<template>
  <TableViewWithTitle title="파트너 추가정보">
    <template v-if="isCanEdit" v-slot:headRight>
      <div class="group_form">
        <span v-if="isCreditAutoSync" class="txt_view_l">최근 갱신 일자</span>
        <strong v-if="isCreditAutoSync" class="tit_util">{{ renewalDate }}</strong>
        <button
          v-if="isCreditAutoSync"
          type="button"
          class="btn_secondary btn_medium"
          @click="onClickCreditSync"
        >
          신용평가 정보 동기화
        </button>
        <span v-if="isCreditAutoSync" class="bar_view" />
        <button type="button" class="btn_secondary btn_medium w96" @click="onClickModify">
          수정
        </button>
      </div>
    </template>
    <template v-slot:body>
      <!-- <tr>
        <th>자동 동기화 사용 여부</th>
        <td colspan="3">
          {{ creditAutoSync }}
        </td>
      </tr> -->
      <tr>
        <th>신용 평가 등급</th>
        <td>{{ partnerInfo ? partnerInfo.creditGrade : "" }}</td>
        <th>등록 점수</th>
        <td>
          <div class="group_form">
            <span class="txt_view">{{ evaluateInfo ? evaluateInfo.totalScore : "" }}</span>
            <div v-if="canEvaluatePartner" class="area_right">
              <span v-if="partnerInfo.renewalDate" class="txt_view_l">평가일자</span>
              <span class="txt_view_l">{{ partnerInfo.renewalDate | dateStringFormat }}</span>
              <button
                v-if="isCanEdit"
                type="button"
                class="btn_fourthly btn_small"
                @click="onClickPartnerEvaluate('ADMIN')"
              >
                거래처 평가
              </button>
              <button
                v-else
                :disabled="!evaluateInfo.totalScore"
                type="button"
                class="btn_fourthly btn_small"
                @click="onClickPartnerEvaluate('NORMAL')"
              >
                거래처 평가
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>현금 흐름 등급</th>
        <td>{{ partnerInfo ? partnerInfo.cashGrade : "" }}</td>
        <th>설립일</th>
        <td>{{ partnerInfo.companyBuildDate | dateStringFormat }}</td>
      </tr>
      <tr>
        <th>기업형태</th>
        <td>{{ partnerInfo ? partnerInfo.companyType : "" }}</td>
        <th>연매출액</th>
        <td>{{ partnerInfo ? partnerInfo.salesAmount : "" }}</td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <td>
          <FileView :dataList="partnerInfo ? partnerInfo.fileList : []" />
        </td>
        <th>선택 군</th>
        <td>{{ partnerInfo ? partnerInfo.refGroupName : "" }}</td>
      </tr>
      <tr>
        <th>대표 취급 품목</th>
        <td colspan="3" class="td_text">
          {{ partnerInfo ? partnerInfo.categoryItem : "" }}
        </td>
      </tr>
      <tr>
        <th>비고</th>
        <td colspan="3" class="h96 td_text">
          {{ partnerInfo ? partnerInfo.note : "" }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

import { getDateStringWithTime, dateToString } from "@/utils/dateUtils";

export default {
  name: "PartnerAddInfo",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    isCanEdit: Boolean,
    partnerInfo: Object,

    canEvaluatePartner: Boolean,
    isCreditAutoSync: Boolean,
    evaluateInfo: Object,
  },
  computed: {
    renewalDate() {
      if (!this.partnerInfo || !this.partnerInfo.renewalDate) return "";
      return getDateStringWithTime(this.partnerInfo.renewalDate);
    },
    creditAutoSync() {
      return "사용안함";
    },
  },
  methods: {
    onClickModify() {
      this.$emit("onClickModify");
    },
    onClickPartnerEvaluate(type) {
      this.$emit("onClickPartnerEvaluate", type);
    },
    onClickCreditSync() {
      let nowDate = new Date();
      let renewalDate = new Date(this.renewalDate);

      renewalDate.setDate(renewalDate.getDate() + 1);

      if (nowDate > renewalDate || this.renewalDate == "") {
        this.$emit("onClickCreditSync");
      } else {
        this.$emit(
          "alert",
          "이미 신용평가 정보 동기화를 하셨습니다.<br> 마지막 동기화 후 24시간이 지나야 다시 가능합니다.",
        );
      }
    },
  },
};
</script>
