<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">협력사 상세 정보</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="협력사 상세 정보">
          <template v-slot:body>
            <tr>
              <th>상호</th>
              <td colspan="3" class="td_text" v-html="itemData.companyName" />
            </tr>
            <tr>
              <th>사업자 번호</th>
              <td>{{ itemData.corporateNum }}</td>
              <th>상태</th>
              <td>
                <div v-if="isCanEdit" class="group_form">
                  <Radio
                    name="radioStatus"
                    :dataList="radioStatusList"
                    :selectedId.sync="radioStatusId"
                    @onChange="onChangedStatus"
                  />
                  <button
                    type="button"
                    class="btn_fourthly btn_small"
                    style="margin-left: 20px"
                    :disabled="radioStatusId !== 'N'"
                    @click="onClickReviewOpinion"
                  >
                    검토 의견
                  </button>
                </div>
                <span v-else>{{ itemData.statusName }}</span>
              </td>
            </tr>
            <tr>
              <th>대표자</th>
              <td :colspan="isCanEdit ? '1' : '3'">
                {{ itemData.ceo }}
              </td>
              <th v-if="isCanEdit">비밀번호 초기화</th>
              <td v-if="isCanEdit">
                <button type="button" class="btn_fourthly btn_small" @click="onClickResetPass">
                  비밀번호 초기화 메일 발송
                </button>
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="3" class="td_text">
                {{ addressTextFull }}
              </td>
            </tr>
            <tr>
              <th>대표 전화번호</th>
              <td>{{ itemData.tel }}</td>
              <th>대표 이메일</th>
              <td>
                <InputEmail
                  style="display: inline-block; vertical-align: top; margin-right: 5px"
                  :isDisabled="!$isAdmin || radioStatusId !== 'C'"
                  :emailName.sync="emailName"
                  :emailDomain.sync="emailDomain"
                  :isMailPushBtn="false"
                />
                <button
                  v-if="$isAdmin && radioStatusId === 'C'"
                  class="btn_fourthly btn_small"
                  @click="onClickEmailChange"
                >
                  대표 이메일 변경
                </button>
              </td>
            </tr>
            <tr>
              <th>파트너 신청일</th>
              <td>{{ regDate }}</td>
              <th>상태 변경일</th>
              <td>{{ statusDate }}</td>
            </tr>
            <tr>
              <th>업태</th>
              <td class="td_text">
                {{ itemData.businessType }}
              </td>
              <th>업종</th>
              <td class="td_text">
                {{ itemData.business }}
              </td>
            </tr>
            <tr>
              <th>사업자등록증</th>
              <td colspan="3">
                <FileView :dataList="businessRegFileList" />
              </td>
            </tr>
            <tr>
              <th>계좌번호<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <InputBank
                  :isDisabled="!$isAdmin || radioStatusId !== 'C'"
                  :bankDepositor.sync="bankDepositor"
                  :bankCode.sync="bankCode"
                  :bankNumber.sync="bankNumber"
                />
              </td>
              <th>통장사본<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <FileView :dataList="bankBookFile" />
                <FileWrite
                  v-if="$isAdmin && radioStatusId === 'C'"
                  :canMultiple="false"
                  :dataList="bankBookFile"
                  @fileUploaded="(file) => (bankBookFile = file)"
                  @fileRemoved="(fileToRemove) => (bankBookFile = [])"
                />
              </td>
            </tr>
            <tr>
              <th>첨부 파일</th>
              <td colspan="3">
                <FileView :dataList="itemData.attachFileList || []" />
              </td>
            </tr>
            <tr>
              <th>참고 URL</th>
              <td colspan="3" class="td_text">
                {{ itemData.siteUrl }}
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <div class="area_view">
          <TableHead title="담당자 정보" />
          <Board
            :disableHover="true"
            :dataList="itemData.partnerPersonList || []"
            :showPagination="false"
          >
            <template v-slot:tr>
              <th>이름</th>
              <th>직급</th>
              <th>직무</th>
              <th>전화번호 (내선)</th>
              <th>휴대전화</th>
              <th>이메일</th>
            </template>
            <template v-slot:rows>
              <template v-for="(item, index) in itemData.partnerPersonList || []">
                <tr :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.jobgrade }}</td>
                  <td>{{ item.job }}</td>
                  <td>{{ item.tel }}</td>
                  <td>{{ item.mobile }}</td>
                  <td class="td_ellip align_left" :data-ellip="item.email">
                    {{ item.email }}
                  </td>
                </tr>
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="6" class="td_empty">담당자가 없습니다.</td>
              </tr>
            </template>
          </Board>
        </div>

        <!-- 파트너 추가정보 -->
        <PartnerAddInfoWrite
          v-if="isModifyMode"
          :model="partnerAddInfoSaved"
          :evaluateInfo="evaluateInfo"
          :isCreditAutoSync="isCreditAutoSync"
          @onClickCancel="onClickCancel"
          @onClickSave="onClickSave"
          @alert="alert"
        />

        <PartnerAddInfoView
          v-else
          :isCanEdit="isCanEdit"
          :partnerInfo="itemData.partnerInfo || {}"
          :evaluateInfo="evaluateInfo"
          :canEvaluatePartner="canEvaluatePartner"
          :isCreditAutoSync="isCreditAutoSync"
          @alert="alert"
          @onClickModify="onClickModify"
          @onClickCreditSync="onClickCreditSync"
          @onClickPartnerEvaluate="onClickPartnerEvaluate"
        />
        <div class="area_view">
          <TableHead title="파트너 거래 현황" />
          <PartnerTradeFilterBar @onClickSearch="onClickSearch" />
          <Board :disableHover="true" :dataList="partnerTradeList" :showPagination="false">
            <!-- <template v-slot:colgroup>

            </template> -->
            <template v-slot:tr>
              <th>유형</th>
              <th>입찰일</th>
              <th>입찰 번호</th>
              <th>입찰명</th>
              <th>발주일</th>
              <th>발주 번호</th>
              <th>발주명</th>
              <th>파트너사 담당자</th>
            </template>
            <template v-slot:rows>
              <template v-for="item in partnerTradeList">
                <PartnerTradeListLine :key="item.id" :rowData="item" />
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="8" class="td_empty">파트너 거래 현황이 없습니다.</td>
              </tr>
            </template>
          </Board>
        </div>
        <div class="area_view">
          <TableHead title="수행도평가 진행 내역" />
          <Board :disableHover="true" :dataList="performanceDataList || []" :showPagination="false">
            <template v-slot:colgroup>
              <col style="width: 120px" />
              <col style="width: 250px" />
              <col style="width: 110px" />
              <col style="width: 160px" />
              <col style="width: 160px" />
              <col style="width: 100px" />
              <col style="width: 140px" />
            </template>
            <template v-slot:tr>
              <th>진행상태</th>
              <th>발주명</th>
              <th>평가일자</th>
              <th>평가자</th>
              <th>검수 번호</th>
              <th>총점</th>
              <th>평가결과보기</th>
            </template>
            <template v-slot:rows>
              <template v-for="(item, index) in performanceDataList">
                <PerformanceListLine
                  :key="index"
                  :rowData="item"
                  @onClickPerformanceResult="onClickPerformanceResult"
                />
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="7" class="td_empty">수행도평가 진행 내역이 없습니다.</td>
              </tr>
            </template>
          </Board>
        </div>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <PdfConverter
          ref="PdfConverter"
          :apiPath="`${$apiPath.PDF_HISTORY}/partner`"
          :detailData="detailData"
          @sendPaymentCb="sendPaymentCb"
        >
        </PdfConverter>
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>

        <template v-if="(radioStatusId === 'P' || radioStatusId === 'N') && isPartnerEvaluate">
          <template v-if="$isAdmin">
            <template v-if="!ingPayment">
              <button class="btn_primary btn_large" @click="onClickPaymentPartner">전자결재</button>
            </template>
            <template v-else>
              <button
                class="btn_primary btn_large"
                @click="
                  () => {
                    $router.push(`${computedPaymentPath}/${itemData.aprvlInfo.docNo}`);
                  }
                "
              >
                전자결재 - {{ itemData.aprvlInfo.docStatusName }}
              </button>
            </template>
          </template>
        </template>
      </div>
      <div class="area_right">
        <template v-if="$isAdmin && radioStatusId === 'C'">
          <button type="button" class="btn_primary btn_large" @click="onClickUpdate">
            계좌번호 업데이트
          </button>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isPopChangeStatus"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        alertText="파트너 상태를 변경하시겠습니까?<br>변경한 상태에 대한 메일이 발송됩니다."
        @onClickFourtyly="onClickCancelStatus"
        @onClickPrimary="onClickChangeStatus"
      />

      <ReviewPopup
        v-if="isPopReview"
        :reviewOpinion="reviewOpinion"
        @onClickClose="onClickClosePopReview"
        @onClickSubmit="onClickSubmitPopReview"
      />
      <PartnerEvaluatePopup
        v-if="isPopPartnerEvaluate"
        :model="evaluateData"
        :templateOption="templateOption"
        :isEvaluated="isEvaluated"
        :templateType="templateType"
        @alert="alert"
        @onClickClose="onClickClosePopPartnerEvaluate"
        @onClickSubmit="onClickSubmitPopPartnerEvaluate"
        @onClickAgain="onClickAgainPopPartnerEvaluate"
      />
      <PartnerEvaluateViewPopup
        v-if="isPopPartnerEvaluateView"
        :model="evaluateData"
        @onClickClose="onClickClosePopPartnerEvaluateView"
      />

      <!-- 수행도평가 -->
      <PerformanceEvaluateResultPopup
        v-if="isPopPerformanceEvaluateResult"
        :evaluateResultData="evaluateResultData"
        @onClickClose="onClickClosePopPerformanceEvaluateResult"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import InputEmail from "@/components/common/input/InputEmail";
import InputBank from "@/components/common/input/InputBank";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@/components/common/file/FileView";
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import PdfConverter from "@/components/shared/PdfConverter";
import PartnerAddInfoWrite from "@/components/admin/partner/write/PartnerAddInfoWrite";
import PartnerAddInfoView from "@/components/admin/partner/view/PartnerAddInfo";
import PartnerTradeFilterBar from "@/components/admin/partner/view/PartnerTradeFilterBar";
import PartnerTradeListLine from "@/components/admin/partner/view/PartnerTradeListLine";
import PerformanceListLine from "@/components/admin/partner/view/PerformanceListLine";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ReviewPopup from "@/components/admin/partner/popup/ReviewPopup";
import PartnerEvaluatePopup from "@/components/admin/partner/popup/PartnerEvaluatePopup";
import PerformanceEvaluateResultPopup from "@/components/shared/performanceEvaluate/PerformanceEvaluateResultPopup";

import PageMixin from "@/mixins/PageMixin";
import { mapState } from "vuex";
import { PARTNER_VIEW_ACTION, GET_PARTNER_GUBUNS_ACTION } from "@/store/modules/partner/action";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";
import { getDateString, dateToString } from "@/utils/dateUtils";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { getAddressTextFull } from "@/utils/spUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import PartnerAddInfoWriteModel from "@/components/admin/partner/write/PartnerAddInfoWriteModel";
import PerformanceWriteModel from "@/pages/admin/performance/PerformanceWriteModel";
import PerformanceViewModel from "@/pages/admin/performance/PerformanceViewModel";
import PartnerEvaluateViewPopup from "@/components/admin/partner/popup/PartnerEvaluateViewPopup";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "PartnerView",
  components: {
    PartnerEvaluateViewPopup,
    PageWithLayout,
    TableViewWithTitle,
    Radio,
    InputBank,
    InputEmail,
    FileWrite,
    FileView,
    TableHead,
    Board,
    PdfConverter,
    PartnerAddInfoWrite,
    PartnerAddInfoView,
    PartnerTradeFilterBar,
    PartnerTradeListLine,
    PerformanceListLine,
    Sticky,
    AlertPopup,
    ReviewPopup,
    PartnerEvaluatePopup,
    PerformanceEvaluateResultPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      bankDepositor: "",
      bankCode: "",
      bankNumber: "",
      bankBookFile: [],
      emailName: "",
      emailDomain: "",

      itemId: String,
      isModifyMode: false,
      radioStatusList: [
        { id: "C", text: "승인" },
        { id: "N", text: "검토" },
        { id: "R", text: "거부" },
        { id: "P", text: "대기", isDisabled: true },
      ],
      radioStatusId: "",
      radioPrev: "", // alert 띄우고 취소하면 이전 값으로 돌아가야 함.

      isPopChangeStatus: false,
      partnerTradeList: [],

      performanceDataList: [],

      isPopReview: false,
      reviewOpinion: "",

      evaluateData: new PerformanceWriteModel(),
      isPopPartnerEvaluate: false,
      isEvaluated: false,

      partnerAddInfoSaved: new PartnerAddInfoWriteModel(),

      isPopPerformanceEvaluateResult: false,

      evaluateResultData: {},

      isCreditAutoSync: false,
      canEvaluatePartner: false,

      templateOption: [],
      templateType: "PA",

      isPopPartnerEvaluateView: false,
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.partner.statusList,
      gubuns: (state) => state.partnet.gubuns,
      bankCodeList: (state) => state.commonCode.bankCodeList,
    }),
    isCanEdit() {
      return this.$isAdmin;
    },
    businessRegFileList() {
      const { businessRegFile } = this.itemData;
      if (!businessRegFile) return [];

      return [businessRegFile];
    },

    addressTextFull() {
      if (!this.itemData) return "";

      return getAddressTextFull(this.itemData.address, this.itemData.address2);
    },

    regDate() {
      if (!this.itemData.regDate) return "";
      return getDateString(this.itemData.regDate);
    },
    statusDate() {
      if (!this.itemData.statusDate) return "";
      return getDateString(this.itemData.statusDate);
    },

    evaluateInfo() {
      return this.itemData.evaluateInfo || {};
    },
    computedBankName() {
      const bankCode = this.itemData.bankCode;
      if (!bankCode) {
        return "-";
      }
      const bankObj = this.bankCodeList.find((item) => item.code === bankCode);
      return bankObj.name;
    },
    detailData() {
      const reqPath = `${this.$route.path}?isEvaluate=true`;
      const reqTitle = `${this.itemData.companyName}_평가`;
      const refPk = `${this.itemId}`;
      const menuId = "info/partner";
      let detailData = {
        refPk,
        reqPath,
        menuId,
        reqTitle,
      };
      return detailData;
    },
    isPartnerEvaluate() {
      return String(this.itemData?.evaluateInfo?.totalScore);
    },
    computedPaymentPath() {
      const { docStatus } = this.itemData.aprvlInfo;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    ingPayment() {
      if (!this.itemData.aprvlInfo) {
        return;
      }
      const { docStatus, docStatusName, docNo } = this.itemData.aprvlInfo;

      return docNo ? true : false;
    },
  },
  beforeMount() {
    const { GET_BANK_CODE } = COMMON_CODE_ACTION;
    this.$store.dispatch(GET_BANK_CODE);
    this.itemId = this.$route.params.id;

    this.$store.dispatch(PARTNER_VIEW_ACTION).then(() => {
      this.init();
    });
    this.$store.dispatch(GET_PARTNER_GUBUNS_ACTION);

    this.isCreditAutoSync = LocalStorageManager.shared.getIsCreditAutoSync();
    this.canEvaluatePartner = LocalStorageManager.shared.getCanEvaluatePartner();
  },
  methods: {
    async init() {
      const path = `${this.$apiPath.PARTNER}/${this.itemId}`;
      await this.getData(path);

      // const tradePath = `${ this.$apiPath.PARTNER }/${ this.itemId }${ this.$apiPath.BUSINESS }?status=F`;
      const defaultStatusType = "0";
      const tradePath = `${this.$apiPath.PARTNER}/${this.itemId}${this.$apiPath.BUSINESS}?status=${defaultStatusType}`;
      await this.getBusinessData(tradePath);

      await this.getPerformanceData();

      if (this.$isAdmin && !this.isPartnerEvaluate && this.radioStatusId === "P") {
        this.onClickPartnerEvaluate("ADMIN");
      }
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      if (!result.data) return;

      this.itemData = result.data;
      this.radioStatusId = this.itemData.status;
      this.bankDepositor = this.itemData.bankDepositor;
      this.bankCode = this.itemData.bankCode;
      this.bankNumber = this.itemData.bankNumber;
      if (this.itemData.bankBookFile) {
        this.bankBookFile = [this.itemData.bankBookFile] || [];
      }
      this.emailName = this.itemData.email.split("@")[0];
      this.emailDomain = this.itemData.email.split("@")[1];

      const { review, evaluateInfo } = this.itemData;
      this.reviewOpinion = review || "";

      if (this.itemData && this.itemData.partnerInfo) {
        this.partnerAddInfoSaved.setData(this.itemData.partnerInfo);
      }

      this.evaluateData.templateType = this.templateType;
      if (!evaluateInfo.evaluateNum) {
        this.isEvaluated = false;
        this.evaluateData.setPartnerData(this.itemData);
      } else {
        this.isEvaluated = true;
        const data = await this.getEvaluateData(evaluateInfo.evaluateNum);
        this.evaluateData.setPartnerModifyData(this.itemData, data);
      }

      if (this.$route.query?.isEvaluate) {
        this.onClickPartnerEvaluate("NORMAL");
      }
    },
    async getBusinessData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.partnerTradeList = [];

        return;
      }

      this.partnerTradeList = result.data;
    },
    async onClickResetPass() {
      const obj = {
        to: this.itemData.email,
        userNum: this.itemData.userNum,
      };

      const path = this.$apiPath.MAIL_PASS_RESET;

      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.alert("대표 이메일로 비밀번호 초기화 메일을 발송하였습니다.");
    },
    // 신용평가 정보 동기화
    onClickCreditSync() {
      this.getCreditSync();
    },
    async getCreditSync() {
      this.$store.dispatch(PROGRESS_START_ACTION);

      const userNum = this.itemData.userNum;
      const corporateNum = this.itemData.corporateNum;
      const path = `${this.$apiPath.CREDIT_SYNC}/${userNum}/${corporateNum}`;

      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      await this.getData(`${this.$apiPath.PARTNER}/${this.itemId}`);

      // this.itemData.partnerInfo = result.data;
      // this.partnerAddInfoSaved.setData( this.itemData.partnerInfo );

      this.alert("신용평가 정보가 동기화 되었습니다.");
    },
    onClickModify() {
      this.isModifyMode = true;
    },
    onClickCancel() {
      this.isModifyMode = false;
    },
    onClickSave(item) {
      const id = this.$route.params.id;
      const path = `${this.$apiPath.PARTNER}/${id}/info`;

      this.saveAddInfo(path, item);
    },
    async saveAddInfo(path, item) {
      const result = await ApiService.shared.putData(path, item);
      if (!result.data) return;

      // 수정하고 넘겨준다.
      this.itemData.partnerInfo = result.data;

      this.partnerAddInfoSaved.setData(this.itemData.partnerInfo);

      this.isModifyMode = false;
    },
    onChangedStatus(to, from) {
      this.radioPrev = from;

      if (to === "C") {
        if (this.itemData?.aprvlInfo?.docStatus !== "C") {
          this.alert("거래처 평가후 전자결재를 진행하여 주세요");
          this.init();
          return;
        }
      }
      // this.isPopChangeStatus = true;

      if (to === "N") {
        // 검토
        this.isPopReview = true;
      } else {
        this.isPopChangeStatus = true;
      }
    },
    onClickChangeStatus() {
      this.isPopChangeStatus = false;

      const obj = this.getObjToSend();
      this.changeStatus(this.radioStatusId, obj);
    },
    onClickSubmitPopReview(text) {
      this.isPopReview = false;

      const obj = this.getObjToSend();
      obj.review = text;

      this.changeStatus(this.radioStatusId, obj);
    },
    onClickCancelStatus() {
      this.resetStatus();
    },
    onClickClosePopReview() {
      this.resetStatus();
    },
    onClickReviewOpinion() {
      this.radioPrev = "N";

      this.isPopReview = true;
    },
    resetStatus() {
      // 이전 상태 값으로 돌려준다.
      this.radioStatusId = this.radioPrev;

      this.isPopChangeStatus = false;
      this.isPopReview = false;
    },
    getObjToSend() {
      let obj = {};

      const userData = LocalStorageManager.shared.getUserData();
      if (!userData) return obj;

      const { managerNum } = userData;
      if (managerNum !== 0 && !managerNum) return obj;

      obj.managerNum = managerNum;

      // review 가 필요한 곳에서는 추가할 것.

      return obj;
    },
    async changeStatus(value, obj) {
      if (value === "P") return;

      const url = `${this.$apiPath.PARTNER}/${this.itemId}/status/${value}`;

      const result = await ApiService.shared.putData(url, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.reviewOpinion = obj.review;

      this.alert("상태가 변경되었습니다.");
    },

    onClickGoToList() {
      // 그냥 약식으로 이렇게 처리합니다.
      // 수행도 평가 결과로 부터 온 경우. query tab=result 값이 붙어서 온다.
      const isFromPerformanceList = this.$route.query.tab == "result";

      this.$router.push({
        path: this.$routerPath.PARTNER_LIST,
        query: isFromPerformanceList ? {} : this.$route.query,
      });
    },
    onClickSearch(obj) {
      const { dates, selectBoxStatusCurrentValue } = obj;

      // status 수정 필요.
      var path = `${this.$apiPath.PARTNER}/${this.itemId}${this.$apiPath.BUSINESS}?status=${selectBoxStatusCurrentValue}`;

      if (dates && dates.length === 2) {
        const startDate = dateToString(dates[0]);
        const endDate = dateToString(dates[1]);

        // path += hasSearchWord ? '&' : '?';
        path += "&";

        path += `startDate=${startDate}&endDate=${endDate}`;
      }

      this.getBusinessData(path);
    },
    // 거래처평가 ? 업체 평가 ? 파트너평가?
    async onClickPartnerEvaluate(type) {
      if (type === "ADMIN") {
        this.templateOption = await this.getEvaluateTemplate(this.templateType);
        this.isPopPartnerEvaluate = true;
      } else if (type === "NORMAL") {
        this.isPopPartnerEvaluateView = true;
      }
    },
    async getEvaluateTemplate(type) {
      const path = `${this.$apiPath.ST_EVALUATE_TEMPLATE}/${type}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        return [];
      }

      return result.data.map((item) => {
        return {
          code: item.templateId,
          name: item.templateName,
          ...item,
        };
      });
    },

    onClickPaymentPartner() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `전자결재 하시겠습니까?`,
        onClickY: () => {
          this.$refs.PdfConverter.generateReport();
        },
      });
    },
    sendPaymentCb(result) {
      const { code, data, text } = result;
      if (code === "200") {
        const params = {
          text: `전자결재문서가 등록되었습니다.<br />결재문서를 상신하시겠습니까?`,
          onClickY: () => {
            this.$router.push({
              path: `${this.$paymentRouterPath.PAYMENT_DRAFT_TEMP}/${data.docNo}`,
            });
          },
        };
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      }
    },

    onClickPlaymentGo() {
      this.$router.push({
        path: `${this.computedPaymentPath}/${this.itemData.docNo}`,
      });
    },
    onClickClosePopPartnerEvaluate() {
      this.isPopPartnerEvaluate = false;
    },

    onClickSubmitPopPartnerEvaluate(obj) {
      this.isPopPartnerEvaluate = false;
      this.setPartnerEvaluate(obj);
    },
    async setPartnerEvaluate(data) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}`;

      const result = await ApiService.shared.postData(path, data);
      if (!result.data) return;

      await this.init();
    },
    onClickAgainPopPartnerEvaluate(evaluateNum, obj) {
      this.isPopPartnerEvaluate = false;

      this.setPartnerEvaluateModify(evaluateNum, obj);
    },
    // 재평가
    async setPartnerEvaluateModify(evaluateNum, data) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;

      data.useYn = false;
      const result = await ApiService.shared.postData(path, data);
      if (!result.data) return;

      await this.init();
    },
    async getPerformanceData() {
      const path = `${this.$apiPath.ST_EVALUATE_PARTNER}/${this.itemId}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.performanceDataList = [];

        return;
      }

      this.performanceDataList = result.data;
    },
    onClickPerformanceResult(item) {
      this.getEvaluateResultData(item);
    },
    async getEvaluateResultData(item) {
      const { evaluateNum } = item;
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;
      const result = await ApiService.shared.getData(path);

      const model = new PerformanceViewModel();

      if (!result.data) {
        this.evaluateResultData = {
          companyName: this.itemData.companyName,
          model: model,
        };
        return;
      }

      const { templateId } = result.data;
      const templateData = templateId ? await this.getPerformanceTemplateData(templateId) : {};
      model.setData(templateData, result.data, item);

      this.evaluateResultData = {
        companyName: this.itemData.companyName,
        model: model,
      };

      this.isPopPerformanceEvaluateResult = true;
    },
    async getPerformanceTemplateData(templateId) {
      const path = `${this.$apiPath.ST_EVALUATE}/${templateId}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }
      return result.data;
    },
    async getEvaluateData(evaluateNum) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }

      return result.data;
    },
    onClickClosePopPerformanceEvaluateResult() {
      this.isPopPerformanceEvaluateResult = false;
    },
    onClickClosePopPartnerEvaluateView() {
      this.isPopPartnerEvaluateView = false;
    },
    getAlertMessage() {
      if (!this.bankDepositor) return "예금주를 확인해주세요";
      if (!this.bankCode) return "은행정보를 확인해 주세요";
      if (!this.bankNumber) return "계좌번호를 확인해주세요";
      if (this.bankBookFile.length < 1) return "통장사본을 확인해주세요";

      return null;
    },
    onClickUpdate() {
      const alertMessage = this.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `계좌번호를 업데이트 하시겠습니까?`,
        onClickY: () => {
          this.submitPartnerInfo();
        },
      });
    },
    async submitPartnerInfo() {
      const path = this.$apiPath.PARTNER_ADMIN;
      const obj = {
        ...this.itemData,
        bankDepositor: this.bankDepositor,
        bankCode: this.bankCode,
        bankNumber: this.bankNumber,
        bankBookFile: this.bankBookFile[0],
      };
      const result = await ApiService.shared.putData(`${path}/${this.itemId}`, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert("계좌번호가가 업데이트 되었습니다.");
      this.init();
    },
    onClickEmailChange() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `대표 이메일을 변경 하시겠습니까?`,
        onClickY: () => {
          this.submitEmailChange();
        },
      });
    },
    async submitEmailChange() {
      const path = this.$apiPath.PARTNER_ADMIN;
      const obj = {
        ...this.itemData,
        email: `${this.emailName}@${this.emailDomain}`,
        bankDepositor: this.bankDepositor,
        bankCode: this.bankCode,
        bankNumber: this.bankNumber,
        bankBookFile: this.bankBookFile[0],
      };

      const result = await ApiService.shared.putData(`${path}/${this.itemId}`, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert("대표 이메일이 변경되었습니다.");
      this.init();
    },
  },
};
</script>
