<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">거래처 평가</h3>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="파트너 정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>업체명</th>
              <td>{{ model ? model.companyName : "" }}</td>
              <th>대표자</th>
              <td>{{ model ? model.ceo : "" }}</td>
            </tr>
            <tr>
              <th>평가일</th>
              <td>{{ model.regDate | dateStringFormat }}</td>
              <th>평가자</th>
              <td>{{ model ? model.regId : "" }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle title="평가 템플릿">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 724px" />
          </template>
          <template v-slot:body>
            <tr>
              <td>평가템플릿 선택</td>
              <td>
                <SelectBox
                  class="w152"
                  :dataList="templateOption"
                  :value.sync="templateId"
                  @onChange="onChangeTemplateType"
                />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <template v-if="model.questList && model.questList.length">
          <TableViewWithTitle title="평가항목">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 724px" />
            </template>
            <template v-slot:body>
              <tr v-for="(quest, qIdx) in model.questList" :key="`quest_${qIdx}`">
                <th>{{ qIdx + 1 }}</th>
                <td>
                  <p class="desc_view">{{ quest.question }}</p>
                  <template v-if="quest.optionList.length > 0">
                    <template v-if="quest.optionType === 'S'">
                      <PerformanceRadio
                        :name="`${qIdx}_${quest.optionList[0].optionNum}`"
                        :dataList="quest.optionList"
                        :selectedId.sync="quest.value"
                        :qIdx="qIdx"
                        @onChange="onChangeRadio"
                      />
                    </template>
                    <template v-else-if="quest.optionType === 'M'">
                      <Input
                        :value.sync="quest.value"
                        :maxLength="50"
                        placeholder="직접입력해주세요"
                        @update:value="onChangeText(qIdx, quest)"
                      />
                    </template>
                  </template>
                  <template v-else>
                    <p class="desc_view">등록된 항목이 없습니다.</p>
                  </template>
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
          <TableViewWithTitle v-if="isEvaluated" title="">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 724px" />
            </template>
            <template v-slot:body>
              <tr>
                <th>재평가 사유</th>
                <td>
                  <Textarea :value.sync="model.reason" placeholder="재평가 사유를 입력하세요." />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
          <div class="area_view">
            <TableHead title="평가결과" />
            <div v-if="isEvaluated" class="area_total">
              <dl>
                <dt>평가 결과</dt>
                <dd>{{ score >= model.partnerCutOffPoint ? "합격" : "불합격" }}</dd>
                <!-- <dt>합계 점수</dt>
                <dd>{{ score }} <span class="util_total">점</span></dd> -->
              </dl>
            </div>
            <div class="tbl_foot">
              <p class="desc_tip">
                * 거래처 등록 합격 기준 - 합계 점수 {{ model.partnerCutOffPoint }}점 이상<br />*
                특정 사유로 합격기준에 미달하는 한시적 거래처 등록 필요 시 거래처 등록 추천서 필요
              </p>
            </div>
          </div>
        </template>
        <TableViewWithTitle v-if="isShowExEvaluateList" title="평가내역">
          <template v-slot:colgroup>
            <col style="width: 100px" />
            <col style="width: 120px" />
            <col style="width: 80px" />
            <col style="width: 616px" />
          </template>
          <template v-slot:header>
            <tr class="tr_eval">
              <th>평가일자</th>
              <th>평가자</th>
              <th>총점</th>
              <th>사유</th>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, idx) in model.evaluateData.exEvaluateList" :key="idx">
              <td class="align_center">{{ item.regDate | dateStringFormat }}</td>
              <td class="align_center">{{ item.regId }}</td>
              <td class="align_center">{{ item.totalScore }}</td>
              <td>{{ item.reason }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button
        v-if="isEvaluated"
        :disabled="!templateId"
        type="button"
        class="btn_primary btn_medium"
        @click="onClickAgain"
      >
        재평가진행
      </button>
      <button
        v-else
        :disabled="!templateId"
        type="submit"
        class="btn_primary btn_medium"
        @click="onClickSubmit"
      >
        평가완료
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Textarea from "@/components/common/textarea/Textarea";
import TableHead from "@/components/shared/TableHead";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ApiService from "@/services/ApiService";
import PerformanceRadio from "@/components/admin/performance/write/PerformanceRadio";
import Input from "@/components/common/input/Input";
import PageMixin from "@/mixins/PageMixin";

export default {
  name: "PartnerEvaluatePopup",
  components: {
    Input,
    PerformanceRadio,
    SelectBox,
    Popup,
    TableViewWithTitle,
    Radio,
    InputAutoMoney,
    Textarea,
    TableHead,
  },
  mixins: [PageMixin],
  props: {
    model: Object,
    isEvaluated: Boolean,
    templateOption: Array,
    templateType: String,
  },
  data() {
    return {
      templateId: null,
      selectedScore: [],
      score: 0,
    };
  },
  computed: {
    isShowExEvaluateList() {
      return (
        this.model.evaluateData.exEvaluateList && this.model.evaluateData.exEvaluateList.length > 0
      );
    },
  },
  watch: {
    "model.questList": {
      handler: function (newVal, oldVal) {
        this.setScore();
      },
      deep: true,
    },
  },
  created() {
    if (this.isEvaluated) {
      this.templateId = this.model.evaluateData.templateId;
      this.getPerformanceTemplateData(this.model.evaluateData.templateId);

      this.selectedScore = this.model.evaluateData.answerList.reduce((acc, item, index) => {
        let ret = acc;
        ret.push(item.points);
        return ret;
      }, []);
    } else if (this.templateOption && this.templateOption.length > 0) {
      this.templateId = this.templateOption[0].templateId;
      this.onChangeTemplateType(this.templateId);
    }
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSubmit() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.$emit("onClickSubmit", this.model.getData());
    },
    onClickAgain() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      this.$emit("onClickAgain", this.model.evaluateData.evaluateNum, this.model.getData());
    },
    onChangeTemplateType(val) {
      this.getPerformanceTemplateData(val);
    },
    async getPerformanceTemplateData(id) {
      const path = `${this.$apiPath.ST_EVALUATE}/${id}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.model.templateData = {};
        return;
      }
      const { data } = result;
      this.model.setQuestList(data);
      this.model.templateData = data;

      if (data.questList && data.questList.length > 0) {
      }
    },
    onChangeRadio(idx, item) {
      this.selectedScore[idx] = item.score;
      const target = this.model.questList[idx];

      target.optionNum = item.optionNum;
      target.selection = item.selection;
      target.points = item.score;
    },
    onChangeText(idx, item) {
      this.selectedScore[idx] = item.value.trim().length > 0 ? item.optionList[0].score || 0 : 0;
      item.optionNum = item.optionList[0].optionNum;
      item.points = item.value.trim().length > 0 ? item.optionList[0].score || 0 : 0;
      item.selection = item.value;
    },
    setScore() {
      if (this.isEvaluated) {
        this.score = this.selectedScore.reduce((acc, item, index) => {
          console.log("acc,item :>> ", acc, item);
          let ret = acc;
          ret += item;
          return ret;
        }, 0);
      } else {
        this.score = 0;
      }
    },
  },
};
</script>
<style scoped>
.tr_eval th {
  height: auto !important;
}
</style>
