<template>
  <div class="group_form">
    <SelectBox
      class="w152"
      :dataList="dataList"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      :placeholder="placeholder"
      @update:value="onChangeSelect"
    />
    <Input
      v-if="isDirectMode"
      class="w220"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
    />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";
import Input from "@/components/common/input/Input";

import { mapState } from "vuex";
//import { COMMON_CODE_ACTION } from '@/store/modules/common/action'
import { GET_PARTNER_GUBUNS_ACTION } from "@/store/modules/partner/action";

export default {
  // tableView에 들어가는 선택군 + 직접입력시 tf
  name: "SelectBoxRefGroup",
  components: {
    SelectBox,
    Input,
  },
  props: {
    value: String,
    placeholder: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
    dataList: Array,
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    // ...mapState({
    //   gubuns: state => state.partnet.gubuns,
    // }),
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    // currencySync: {
    //   get() {
    //     return this.value;
    //   },
    //   set( value ) {
    //     this.$emit( 'update:value', value );
    //   }
    // },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  mounted() {
    //this.$store.dispatch( GET_PARTNER_GUBUNS_ACTION );

    this.setValueByProps();
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:value", "");
      } else {
        this.$emit("update:value", value);
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case null:
        case "S":
        case "N":
        case "T":
          toSelectedValue = this.value;
          break;
        default:
          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
