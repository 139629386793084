<template>
  <FilterBarBase :btnBasic="false">
    <dl>
      <dt>유형</dt>
      <dd>
        <SelectBox
          class="w152"
          :dataList="statusList"
          :isDisabled="isDisabledStatus"
          :value.sync="selectBoxStatusCurrentValue"
        />
      </dd>
      <dt>기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates">
          <div class="area_right">
            <button type="button" class="btn_primary btn_small w98" @click="onClickSearch">
              조회
            </button>
          </div>
        </DateRangeQuickSelect>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import { mapState } from "vuex";
// import { GET_BUSINESS_TYPE_LIST } from '@/store/modules/partner/action'

export default {
  name: "PartnerTradeFilterBar",
  components: {
    FilterBarBase,
    SelectBox,
    Radio,
    Input,
    DateRangeQuickSelect,
  },
  data() {
    return {
      dates: [],
      selectBoxStatusCurrentValue: "0",
    };
  },
  // mounted() {
  //   this.$store.dispatch( GET_BUSINESS_TYPE_LIST );
  // },
  computed: {
    ...mapState({
      statusList: (state) => state.partner.businessTypeList,
    }),
    isDisabledStatus() {
      const hasNoData = !this.statusList || this.statusList.length === 0;
      return hasNoData;
    },
  },
  methods: {
    onClickSearch(e) {
      const ret = {
        dates: this.dates,
        selectBoxStatusCurrentValue: this.selectBoxStatusCurrentValue,
      };
      this.$emit("onClickSearch", ret);
    },
  },
};
</script>
