<template>
  <tr>
    <td>{{ rowData.statusName }}</td>
    <td>{{ tenderDate }}</td>
    <td>{{ rowData.postCid }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.postTitle">
      <router-link
        :to="toBidPath"
        class="link_subject"
        target="_blank"
        rel="opener"
        :data-ellip="rowData.postTitle"
        v-html="rowData.postTitle"
      />
    </td>
    <td>{{ orderDate }}</td>
    <td>{{ rowData.orderCid ? rowData.orderCid : "해당 없음" }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.orderTitle || ''">
      <router-link
        v-if="rowData.orderTitle"
        :to="toOrderPath"
        :data-ellip="rowData.orderTitle || ''"
        class="link_subject"
        v-html="rowData.orderTitle"
      />
      <span v-else>해당 없음</span>
    </td>
    <td>{{ rowData.name }}</td>
  </tr>
</template>
<script>
import { getDateString } from "@/utils/dateUtils";
// import { makePathWithQuery } from '@/utils/urlUtils'

export default {
  name: "PartnerTradeListLine",
  props: {
    rowData: Object,
  },
  computed: {
    tenderDate() {
      if (!this.rowData.tenderDate) return "";
      return getDateString(this.rowData.tenderDate);
    },
    orderDate() {
      if (!this.rowData.orderDate) return "해당 없음";

      return getDateString(this.rowData.orderDate);
    },
    toBidPath() {
      const seq = this.rowData.postCid;

      // const path = makePathWithQuery( this.$routerPath.BID_VIEW, seq, this.$route.query );

      // 상세 안의 리스트는 query 전달 안하겠다.
      const path = `${this.$routerPath.BID_VIEW}/${seq}`;

      return path;
    },
    toOrderPath() {
      const seq = this.rowData.orderCid;

      // const path = makePathWithQuery( this.$routerPath.ORDER_VIEW, seq, this.$route.query );

      // 상세 안의 리스트는 query 전달 안하겠다.
      const path = `${this.$routerPath.ORDER_VIEW}/${seq}`;

      return path;
    },
  },
  methods: {
    getRegDate(target) {
      return getDateString(target);
    },
  },
};
</script>
