<template>
  <TableViewWithTitle title="파트너 추가정보">
    <template v-slot:headRight>
      <div class="group_form">
        <button type="button" class="btn_fourthly btn_medium w96" @click="onClickCancel">
          취소
        </button>
        <button type="button" class="btn_secondary btn_medium w96" @click="onClickSave">
          저장
        </button>
      </div>
    </template>
    <template v-slot:body>
      <tr>
        <th>신용 평가 등급</th>
        <td>{{ modelCloned ? modelCloned.creditGrade : "" }}</td>
        <th>등록 점수</th>
        <td>
          <span class="txt_view">{{ evaluateInfo ? evaluateInfo.totalScore : "0" }}</span>
        </td>
      </tr>
      <tr>
        <th>현금 흐름 등급</th>
        <td>{{ modelCloned ? modelCloned.cashGrade : "" }}</td>
        <th>설립일</th>
        <td>{{ modelCloned.companyBuildDate | dateStringFormat }}</td>
      </tr>
      <tr>
        <th>기업형태</th>
        <td>{{ modelCloned ? modelCloned.companyType : "" }}</td>
        <th>연매출액</th>
        <td>{{ modelCloned ? modelCloned.salesAmount : "" }}</td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <td>
          <FileWrite
            :dataList.sync="modelCloned.fileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
        <th>선택 군</th>
        <td>
          <SelectBoxRefGroup
            :dataList="gubunsList"
            :value.sync="modelCloned.refGroup"
            placeholder="선택하세요"
          />
        </td>
      </tr>
      <tr>
        <th>대표 취급 품목<br />(최대 5개 추가)</th>
        <td colspan="3">
          <SearchTag
            :maxLength="maxLength"
            :tagListString.sync="modelCloned.categoryItem"
            :autoDataList="autoDataList"
            @onInputKeyword="onInputKeyword"
            @alert="alert"
          />
        </td>
      </tr>
      <tr>
        <th>비고</th>
        <td colspan="3" class="h96">
          <Textarea :value.sync="modelCloned.note" :maxLength="MAX_COUNT_NOTE" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import SelectBoxRefGroup from "@/components/common/selectBox/SelectBoxRefGroup";
import Textarea from "@/components/common/textarea/Textarea";
import FileWrite from "@/components/common/file/FileWrite";
import SearchTag from "@/components/common/search/SearchTag";
import InputNumber from "@/components/common/input/InputNumber";

import ApiService from "@/services/ApiService";
import { mapState } from "vuex";
import { HANDLE_TITLE_MAX_LENGTH } from "@/constants/options";

export default {
  name: "PartnerAddInfoWrite",
  components: {
    TableViewWithTitle,
    SelectBoxRefGroup,
    Textarea,
    FileWrite,
    SearchTag,
    InputNumber,
  },
  props: {
    model: Object,

    isCreditAutoSync: Boolean,
    evaluateInfo: Object,
  },
  data() {
    return {
      modelCloned: Object.assign({}, this.model),
      MAX_COUNT_NOTE: 100,
      autoDataList: [],
    };
  },
  computed: {
    ...mapState({
      gubuns: (state) => state.partner.gubuns,
    }),
    gubunsList() {
      if (this.gubuns.length < 1) return [];

      const resetGubunsList = this.gubuns.concat({ code: "직접입력", desc: "직접입력" }); // 배열.

      return resetGubunsList;
    },
    maxLength() {
      return HANDLE_TITLE_MAX_LENGTH;
    },
    creditAutoSync() {
      return "사용안함";
    },
  },
  methods: {
    alert(text) {
      this.$emit("alert", text);
    },
    onClickCancel() {
      this.$emit("onClickCancel");
    },
    onClickSave() {
      this.$emit("onClickSave", this.modelCloned);
    },
    fileUploaded(fileList) {
      this.modelCloned.fileList = this.modelCloned.fileList.concat(fileList);
    },
    fileRemoved(file) {
      this.modelCloned.fileList = this.modelCloned.fileList.filter((item) => item !== file);
    },
    onInputKeyword(value) {
      // 아무것도 입력 안했을 때 처리.
      // if( !value ) {
      //   this.autoDataList = [];

      //   return;
      // }

      const path = `${this.$apiPath.HANDLE_ITEM_SEARCH}?name=${value}`;

      this.getAutoCompleteData(path);
    },
    async getAutoCompleteData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.autoDataList = [];
        return;
      }

      this.autoDataList = result.data.map((item) => item.itemTitle);
    },
  },
};
</script>
