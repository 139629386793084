<template>
  <fragment>
    <div class="group_form">
      <Search
        class="w316"
        :dataList="autoDataList"
        :value.sync="tagItem"
        :maxLength="maxLength"
        @setValue="onSetValue"
        @onInputKeyword="onInputKeyword"
        @onFocus="onFocus"
      />
      <button type="button" class="btn_fourthly btn_small" @click="onClickAdd">추가</button>
    </div>
    <ul v-if="tagList" class="list_tag">
      <li v-for="(item, index) in tagList" :key="index">
        <button type="button" class="btn_tag" @click="onClickRemove(item)">
          <span class="ico_purchase ico_cross">삭제</span>{{ item }}
        </button>
      </li>
    </ul>
  </fragment>
</template>

<script>
import Search from "@/components/common/search/Search";

export default {
  name: "SearchTag",
  components: {
    Search,
  },
  props: {
    tagListString: String,
    autoDataList: Array,
    maxLength: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      tagItem: "",
      tagList: [],
    };
  },
  mounted() {
    {
      if (this.tagListString == "") return;
      this.tagList = this.tagListString.split(",");
    }
  },
  methods: {
    onSetValue(item) {
      this.tagItem = item;
    },
    onInputKeyword(keyword) {
      this.$emit("onInputKeyword", keyword);
    },
    alert(text) {
      this.$emit("alert", text);
    },
    onClickAdd() {
      if (this.tagItem.trim() == "") {
        this.alert("품목명을 입력해주세요.");
        this.tagItem = "";
        return;
      } else if (this.tagList.length > 4) {
        this.alert("대표 취급 품목은 최대 5개까지 추가가능합니다.");
        return;
      } else if (this.tagList.indexOf(this.tagItem) > -1) {
        this.alert("이미 등록한 품목명입니다.");
        this.tagItem = "";
        return;
      } else if (this.tagItem.indexOf(",") > -1) {
        this.alert("' , '를 포함한 품목명을 등록할 수 없습니다.");
        this.tagItem = "";
        return;
      } else {
        this.tagList.push(this.tagItem);
        this.tagItem = "";
        this.updateTagListString(this.tagList);
      }
    },
    onClickRemove(itemToRemove) {
      const newTagList = this.tagList.filter((item) => item !== itemToRemove);

      this.tagList = newTagList;
      this.updateTagListString(this.tagList);
    },
    updateTagListString(newArray) {
      if (newArray && newArray != this.tagListString.split(",")) {
        this.$emit("update:tagListString", newArray.join(","));
      }
    },
    onFocus(value) {
      // 포커스 온 했을 때, 키 인풋 처럼 데이터 가져오기.
      this.$emit("onInputKeyword", value);
    },
  },
};
</script>
<style scope>
.list_tag {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 53px;
  margin: 4px -24px -8px 0;
  padding: 0 0 8px 4px;
}
.list_tag li {
  float: left;
  height: 18px;
  margin: 4px 12px 0 0;
  vertical-align: top;
}
.list_tag .btn_tag {
  display: block;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #5551ce;
}
.list_tag .btn_tag .ico_cross {
  margin-right: 4px;
}
</style>
