<template>
  <tr>
    <td>{{ rowData.stateCode }}</td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <button type="button" :class="['link_subject']" @click="onClickOrder(rowData)">
        {{ rowData.title }}
      </button>
    </td>
    <td>{{ rowData.regDate | dateStringFormat }}</td>
    <td>{{ rowData.regId }}</td>
    <td class="td_ellip" :data-ellip="rowData.inspectNum">
      <button type="button" :class="['link_subject']" @click="onClickInspect(rowData)">
        {{ rowData.inspectNum }}
      </button>
    </td>
    <td>{{ rowData.totalScore }}</td>
    <td>
      <button v-if="showResult" class="btn_fourthly btn_small" @click="onClickPerformanceResult">
        평가결과보기
      </button>
    </td>
  </tr>
</template>
<script>
import { getDateString } from "@/utils/dateUtils";
// import { makePathWithQuery } from '@/utils/urlUtils'

export default {
  name: "PerformanceListLine",
  props: {
    rowData: Object,
  },
  // data:{
  //   showResult: false
  // },
  computed: {
    // tenderDate() {
    //   if (!this.rowData.tenderDate) return '';
    //   return getDateString(this.rowData.tenderDate);
    // },
    // orderDate() {
    //   if (!this.rowData.orderDate) return '해당 없음';
    //
    //   return getDateString(this.rowData.orderDate);
    // },
    // toPath() {
    //   const contractId = this.rowData.contractId; // 임시 id값
    //
    //   // 상세 안의 리스트는 query 전달 안하겠다.
    //   const path = `${this.$routerPath.CONTRACT_VIEW}/${contractId}`;
    //
    //   return path;
    // },
    toOrderPath() {
      const seq = this.rowData.orderCid;

      // const path = makePathWithQuery( this.$routerPath.ORDER_VIEW, seq, this.$route.query );
      // 상세 안의 리스트는 query 전달 안하겠다.
      const path = `${this.$routerPath.ORDER_VIEW}/${seq}`;

      return path;
    },
    showResult() {
      return this.rowData.stateCode === "완료";
    },
  },
  methods: {
    onClickPerformanceResult() {
      this.$emit("onClickPerformanceResult", this.rowData);
    },
    onClickOrder(item) {
      this.$windowOpen(`${this.$routerPath.ORDER_VIEW}/${item.orderCid}`);
    },
    onClickInspect(item) {
      this.$windowOpen(`${this.$routerPath.INSPECT_VIEW}/${item.inspectNum}`);
    },
  },
};
</script>
