class PartnerAddInfoWriteModel {
  constructor() {
    this.cashGrade = "";
    this.categoryItem = "";
    this.creditGrade = "";
    this.companyType = "";
    this.salesAmount = "";
    this.companyBuildDate = "";
    this.renewalDate = "";
    this.note = "";
    this.refGroup = null;
    this.refGroupName = "";
    this.score = "";
    this.userNum = "";
    this.fileList = [];
  }

  setData(objRaw) {
    if (objRaw.cashGrade) this.cashGrade = String(objRaw.cashGrade);
    // if( objRaw.categoryItem )  this.categoryItem = String( objRaw.categoryItem );
    if (objRaw.creditGrade) this.creditGrade = String(objRaw.creditGrade);
    if (objRaw.companyType) this.companyType = String(objRaw.companyType);
    if (objRaw.salesAmount) this.salesAmount = String(objRaw.salesAmount);
    if (objRaw.companyBuildDate) this.companyBuildDate = String(objRaw.companyBuildDate);
    if (objRaw.renewalDate) this.renewalDate = String(objRaw.renewalDate);
    if (objRaw.note) this.note = String(objRaw.note);
    if (objRaw.refGroup) this.refGroup = objRaw.refGroup;
    if (objRaw.refGroupName) this.refGroupName = objRaw.refGroupName;
    if (objRaw.score) this.score = String(objRaw.score);
    if (objRaw.userNum) this.userNum = objRaw.userNum;
    // if( objRaw.fileList )  this.fileList = objRaw.fileList;

    this.categoryItem = objRaw.categoryItem ? String(objRaw.categoryItem) : "";
    this.fileList = objRaw.fileList || [];
  }

  // , 붙여서 나간다.
  getData() {
    const obj = {
      cashGrade: this.cashGrade,
      categoryItem: this.categoryItem,
      creditGrade: this.creditGrade,
      companyType: this.companyType,
      salesAmount: this.salesAmount,
      companyBuildDate: this.companyBuildDate,
      renewalDate: this.renewalDate,
      note: this.note,
      refGroup: this.refGroup || "",
      refGroupName: this.refGroupName,
      score: this.score,
      userNum: this.userNum,
      fileList: this.fileList,
    };
    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default PartnerAddInfoWriteModel;
